import { Button, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure, useHotkeys } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "@tanstack/react-location";
import { cloneElement, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Chat, db } from "../db";
import { useApiKey } from "../hooks/useApiKey";
import { useChatId } from "../hooks/useChatId";

export function DeleteChatModal({
  chat,
  children,
  isOpen,
}: {
  chat: Chat;
  children: ReactElement;
  isOpen?: boolean;
}) {
  const [opened, { open, close }] = useDisclosure(isOpen);
  const [submitting, setSubmitting] = useState(false);

  const [key, setKey] = useApiKey();

  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(key);
  }, [key]);
  const chatId = useChatId();
  const navigate = useNavigate();

  const onSubmit = async (event: any) => {
    try {
      setSubmitting(true);
      event?.preventDefault();
      await db.chats.where({ id: chat.id }).delete();
      await db.messages.where({ chatId: chat.id }).delete();
      if (chatId === chat.id) {
        navigate({ to: `/` });
      }
      close();

      notifications.show({
        title: "Apagado",
        message: "Conversa apagada.",
      });
    } catch (error: any) {
      if (error.toJSON().message === "Network Error") {
        notifications.show({
          title: "Error",
          color: "red",
          message: "Sem conexão com a internet.",
        });
      } else {
        notifications.show({
          title: "Error",
          color: "red",
          message:
            "Não foi possível apagar a conversa. Por favor atualize a página e tente novamente.",
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {cloneElement(children, { onClick: open })}
      <Modal opened={opened} onClose={close} title="Apagar conversa">
        <form onSubmit={onSubmit}>
          <Stack>
            <Text size="sm">Tem certeza que deseja apagar essa conversa?</Text>
            <Button type="submit" color="red" loading={submitting}>
              Apagar
            </Button>
          </Stack>
        </form>
      </Modal>
    </>
  );
}
