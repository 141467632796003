import { ActionIcon, Flex, Menu } from "@mantine/core";
import { IconDotsVertical, IconPointFilled, IconNumber0, IconNumber1, IconNumber2, IconNumber3, IconNumber4, IconNumber5, IconNumber6, IconNumber7, IconNumber8, IconNumber9 } from "@tabler/icons-react";
import { Link, useNavigate } from "@tanstack/react-location";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Chat, db } from "../db";
import { useChatId } from "../hooks/useChatId";
import { DeleteChatModal } from "./DeleteChatModal";
import { EditChatModal } from "./EditChatModal";
import { MainLink } from "./MainLink";
import { useHotkeys } from "@mantine/hooks";
import { nanoid } from "nanoid";
import { getChatTitle } from "../utils/formaters";

const iconProps = {
  size: "1rem",
};

const iconNumberMap = new Map<string, any>;

iconNumberMap.set('0', <IconNumber0 {...iconProps} />);
iconNumberMap.set('1', <IconNumber1 {...iconProps} />);
iconNumberMap.set('2', <IconNumber2 {...iconProps} />);
iconNumberMap.set('3', <IconNumber3 {...iconProps} />);
iconNumberMap.set('4', <IconNumber4 {...iconProps} />);
iconNumberMap.set('5', <IconNumber5 {...iconProps} />);
iconNumberMap.set('6', <IconNumber6 {...iconProps} />);
iconNumberMap.set('7', <IconNumber7 {...iconProps} />);
iconNumberMap.set('8', <IconNumber8 {...iconProps} />);
iconNumberMap.set('9', <IconNumber9 {...iconProps} />);

const getIconByNumber = (number: number) => {
  const icon = iconNumberMap.get(number.toString()) || <IconPointFilled {...iconProps}/>;

  return icon;
}

export function Chats({ search }: { search: string }) {
  const chatId = useChatId();
  const navigate = useNavigate();
  const chats = useLiveQuery(() =>
    db.chats.orderBy("createdAt").reverse().toArray()
  );
  const filteredChats = useMemo(
    () =>
      (chats ?? []).filter((chat) => {
        if (!search) return true;
        return chat.description.toLowerCase().includes(search);
      }),
    [chats, search]
  );

  const createNumberChatHotKey = (number: number) => {
    return async () => {
      console.log(`CTRL + ${number} = OPEN CHAT ${number}`);
      if (filteredChats.length < 1) return;
      const chat = filteredChats[0];
      navigate({ to: `/chats/${chat.id}` });
    }
  }

  useHotkeys([
    ['ctrl+1', createNumberChatHotKey(1)],
    ['ctrl+2', createNumberChatHotKey(2)],
    ['ctrl+3', createNumberChatHotKey(3)],
    ['ctrl+4', createNumberChatHotKey(4)],
    ['ctrl+5', createNumberChatHotKey(5)],
    ['ctrl+6', createNumberChatHotKey(6)],
    ['ctrl+7', createNumberChatHotKey(7)],
    ['ctrl+8', createNumberChatHotKey(8)],
    ['ctrl+9', createNumberChatHotKey(9)],
    ['d', async () => {
        console.log(`D = DELETE ${chatId}`);
        if (!chatId) return;
        await db.chats.where({ id: chatId }).delete();
        await db.messages.where({ chatId }).delete();
        navigate({ to: `/` });
      }
    ],
    ['e', async () => {
        console.log(`E = EDIT ${chatId}`);
        if (!chatId) return;
      }
    ],
    ['h', async () => {
        console.log(`H = HOME`);
        if (!chatId) return;
        navigate({ to: `/` });
      }
    ],
    ['n', async () => {
        console.log(`N = NEW CHAT`);
        const id = nanoid();

        const lastChat = await db.chats.orderBy("createdAt").reverse().first();
        const incrementId = lastChat ? lastChat.incrementId + 1 : 0;

        db.chats.add({
          id,
          incrementId,
          description: 'Nova conversa',
          totalTokens: 0,
          createdAt: new Date(),
        });
      
        navigate({ to: `/chats/${id}` });
      }
    ],
  ]);

  return (
    <>
      {filteredChats.map((chat, index) => (
        <Flex
          key={chat.id}
          className={chatId === chat.id ? "active" : undefined}
          sx={(theme) => ({
            marginTop: 1,
            "&:hover, &.active": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[1],
            },
          })}
        >
          <Link to={`/chats/${chat.id}`} style={{ flex: 1 }}>
            <MainLink
              icon={getIconByNumber(index + 1)}
              color="teal"
              chat={chat}
              label={getChatTitle(chat)}
            />
          </Link>
          <Menu shadow="md" width={200} keepMounted>
            <Menu.Target>
              <ActionIcon sx={{ height: "auto" }}>
                <IconDotsVertical size={20} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <EditChatModal chat={chat}>
                <Menu.Item>Editar</Menu.Item>
              </EditChatModal>
              <DeleteChatModal chat={chat}>
                <Menu.Item>Apagar</Menu.Item>
              </DeleteChatModal>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      ))}
    </>
  );
}
