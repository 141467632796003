import {
  Badge,
  Button,
  Center,
  Container,
  Group,
  SimpleGrid,
  Text,
  ThemeIcon,
} from '@mantine/core';
import {
  IconBrowser,
  IconKey,
  IconLock,
  IconNorthStar,
} from '@tabler/icons-react';
import { useLiveQuery } from 'dexie-react-hooks';
import { SettingsModal } from '../components/SettingsModal';
import { db } from '../db';
import { config } from '../utils/config';

export function IndexRoute() {
  const settings = useLiveQuery(() => db.settings.get('general'));
  const { openAiApiKey } = settings ?? {};

  return (
    <>
      <Center py="xl" sx={{ height: '100%' }}>
        <Container size="sm">
          <Badge mb="lg">Pronto para o GPT-4</Badge>
          {/* <Text>
            <Logo style={{ maxWidth: 240 }} />
          </Text> */}
          <Text mt={4} size="xl">
            Vigogh Conversa
          </Text>
          <SimpleGrid
            mt={50}
            cols={3}
            spacing={30}
            breakpoints={[{ maxWidth: 'md', cols: 1 }]}
          >
            {features.map((feature) => (
              <div key={feature.title}>
                <ThemeIcon variant="outline" size={50} radius={50}>
                  <feature.icon size={26} stroke={1.5} />
                </ThemeIcon>
                <Text mt="sm" mb={7}>
                  {feature.title}
                </Text>
                <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
                  {feature.description}
                </Text>
              </div>
            ))}
          </SimpleGrid>
          <Group mt={50}>
            {config.allowSettingsModal && !openAiApiKey && (
              <SettingsModal>
                <Button
                  size="md"
                  variant={openAiApiKey ? 'light' : 'filled'}
                  leftIcon={<IconKey size={20} />}
                >
                  Entrar com a chave de API da OpenAI
                </Button>
              </SettingsModal>
            )}
          </Group>
        </Container>
      </Center>
    </>
  );
}

const features = [
  {
    icon: IconBrowser,
    title: 'Confiável',
    description:
      'Vigogh Conversa é distribuído pela Cloudflare e fica inteiramente em seu navegador.',
  },
  {
    icon: IconLock,
    title: 'Privado',
    description:
      'Você está no controle. Todos os seus dados são armazenados em seu dispositivo.',
  },
  {
    icon: IconNorthStar,
    title: 'Transparente',
    description:
      'A política de privacidade e os termos de uso da Open AI se aplicam, já que ela processa suas mensagens.',
  },
];
