// @ts-nocheck

import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components/App";
import { loadConfig } from "./utils/config";

if (navigator.serviceWorker) {
  navigator.serviceWorker.register(
    new URL('./static/service-worker.js', import.meta.url),
    {type: 'module'}
  );
}

loadConfig().then(() => {
  const container = document.getElementById("app");
  const root = ReactDOM.createRoot(container!);
  root.render(<App />);
});
