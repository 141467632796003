import {
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { cloneElement, ReactElement } from "react";
import {
  IconSquareNumber1,
  IconSquareNumber9,
  IconSquareLetterD,
  IconSquareLetterE,
  IconSquareLetterN,
  IconSquareLetterH
} from "@tabler/icons-react";

const BoxCentered = ({ children }: any) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
    {children}
  </div>
);

export function ShortcutsModal({ children }: { children: ReactElement }) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      {cloneElement(children, { onClick: open })}
      <Modal opened={opened} onClose={close} title="Atalhos" size="lg">
        <Stack>
          <BoxCentered>CTRL +&nbsp; <IconSquareNumber1 size={20} />&nbsp; abre conversa número 1.</BoxCentered>
          <BoxCentered>CTRL +&nbsp; <IconSquareNumber9 size={20} />&nbsp; abre conversa número 9.</BoxCentered>
          <BoxCentered>CTRL +&nbsp; ENTER enviar mensagem.</BoxCentered>
          <BoxCentered><IconSquareLetterN size={20} />&nbsp; Abrir nova conversa.</BoxCentered>
          <BoxCentered><IconSquareLetterE size={20} />&nbsp; Editar conversa atual.</BoxCentered>
          <BoxCentered><IconSquareLetterD size={20} />&nbsp; Excluir conversa atual.</BoxCentered>
          <BoxCentered><IconSquareLetterH size={20} />&nbsp; Voltar para a página inicial.</BoxCentered>
          <Text size="sm" color="dimmed">
            Certifique-se de não estar com o foco em qualquer uma das partes da página
          </Text>
        </Stack>
      </Modal>
    </>
  );
}
